"use client";

import { FC, useContext, useEffect } from "react";
import { BannersContext, PopupBannersContext } from "@finbackoffice/site-core";
import { IBannerResponse, IPopupResponse } from "@finbackoffice/clientbff-client";

export const Page: FC<{
    children: React.ReactNode;
    banners?: IBannerResponse[];
    popupBanners?: IPopupResponse[];
}> = ({ children, banners, popupBanners }) => {
    const { setBannersState } = useContext(BannersContext);
    const { setPopups } = useContext(PopupBannersContext);

    useEffect(() => {
        setBannersState(banners || []);
    }, [banners, setBannersState]);

    useEffect(() => {
        setPopups(popupBanners || []);
    }, [popupBanners, setPopups]);

    return <>{children}</>;
};
